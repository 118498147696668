<template>
  <el-dialog title="验证"
             width="50%"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             @close="$emit('close', '')"
             v-model="dialogShow">
    <el-row justify="center" v-loading="$store.getters.isLoading">
      <el-col :span="20">
        <el-form
            ref="formRef"
            label-position="top"
            :model="form" :rules="rules">
          <el-form-item style="margin-bottom: 0;" label="为了你的账户安全，请验证身份">
          </el-form-item>
          <el-form-item>
            <el-input style="width: 100%" disabled :placeholder="`使用手机${mobile}验证`"></el-input>
          </el-form-item>
          <el-form-item prop="smscode">
            <el-row justify="space-around" :gutter="20">
              <el-col :span="18">
                <el-input v-model="form.smscode" placeholder="请输入验证码"></el-input>
              </el-col>
              <el-col :span="6">
                <el-row justify="end">
                  <el-button @click="sendSmscode" :disabled="countDown > 0">
                    {{ countDown > 0 ? `${countDown}s后重新获取` : '获取验证码' }}
                  </el-button>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <el-button @click="dialogShow = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import finance from "@/request/api/finance";
import {ElMessage} from "element-plus";

const props = defineProps({
  mobile: {required: true},
  rechargeNo: {required: true},
  bankcardId: {required: true},
})

const {proxy} = getCurrentInstance()

const dialogShow = ref(true)
const formRef = ref()
const form = ref({
  recharge_no: props.rechargeNo,
  bankcard_id: props.bankcardId,
  smscode: ''
})

const rules = {
  smscode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
}

const countDown = ref(0)

const startCountDown = () => {
  countDown.value = 59
  const interval = setInterval(() => {
    countDown.value--
    if (countDown.value <= 0) {
      clearInterval(interval)
      countDown.value = 0
    }
  }, 1000)
}

onMounted(() => {
  startCountDown()
})

const sendSmscode = async () => {
  let res = await finance.sendConfirmTongLianSmsCode(form.value.recharge_no, form.value.bankcard_id)
  if (res.code != 200) return ElMessage.error('验证码发送失败，请重试')
  startCountDown()
  ElMessage.success('验证码发送成功')

}

const submit = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return
  let res = await finance.confirmTongLianPay(form.value)
  if (res.code != 200) return ElMessage.error('支付失败，请重试')

  ElMessage.success({
    message: '支付成功', duration: 1000, onClose() {
      proxy.$router.push('/recharge_list')
    }
  })
}

</script>